import DataBox from "../../../shared/DataBox";
import { BiLock, BiSolidPencil } from "react-icons/bi";
import { TbExchange } from "react-icons/tb";
import skipIcon from '../../../../images/svg/float/skip_blue.svg';
import clsx from "clsx";
import { useState } from "react";
import Table from "components/Table";
import { useEffect } from "react";
import { getFloatPatchReqs, getFloatPatchStats } from "lib/auth";
import { toast } from "react-toastify";
import { dateFormatter } from "utils/helpers";
import Status from "components/Status";
import { useNavigate } from "react-router-dom";

export default function PatchMain ({setHeader}) {
    const [path, setPath] = useState('all');
    const [stats, setStats] = useState({});
    const [patches, setPatches] = useState({
        allpatch: [],
        changeBalance: [],
        freezeCollection: [],
        skipCollection: [],
    })
    const navigate = useNavigate();

    useEffect(()=>{
        setHeader();
        getPatchStats();
        getPatchLists();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getPatchLists = () => {
        getFloatPatchReqs()
            .then((response) => response.data)
            .then((res) => {
                if (res.status === 'success') {
                    setPatches(res.data)
                } else {
                    toast.error("Something went wrong, please try again!!!")
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                }
            });
    }

    const getPatchStats = () => {
        getFloatPatchStats()
            .then((response) => response.data)
            .then((res) => {
                if (res.status === 'success') {
                    setStats(res.data)
                } else {
                    toast.error("Something went wrong, please try again!!!")
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                }
            });
    }

    return(
        <div className="mx-10 my-[30px]">
            <div className="grid grid-cols-4 gap-x-[20px]">
                <DataBox
                    icon={<BiSolidPencil className='w-[20px] text-ee-primary' />}
                    value={stats?.allpatchCount | 0}
                    title="Total Patch Request sent"
                />
                <DataBox
                    icon={<TbExchange className='w-[20px] text-ee-primary' />}
                    value={stats?.changeBalanceCount | 0}
                    title="Change Balance Amount"
                />
                <DataBox
                    icon={<BiLock className='w-[20px] text-ee-primary' />}
                    value={stats?.freezeCollectionCount | 0}
                    title="Freeze Collections"
                />
                <DataBox
                    icon={<img src={skipIcon} alt="icon" className='w-[20px]' />}
                    value={stats?.skipCollectionCount | 0}
                    title="Skip Collections"
                />
            </div>
            <div className="flex mt-[50px] -mb-[10px]">
                <p 
                    className={clsx(
                        "py-2.5 px-[20px] w-[80px] text-center font-semibold cursor-pointer",
                        path==="all" ? "text-ee-blue-2 bg-ee-blue-1 border-b-[3px] border-b-ee-blue-2" : 'text-ee-grey-2',
                    )} 
                    onClick={()=>setPath('all')}
                >
                    All
                </p>
                <p 
                    className={clsx(
                        "py-2.5 px-[20px] text-center font-semibold cursor-pointer",
                        path==="change" ? "text-ee-blue-2 bg-ee-blue-1 border-b-[3px] border-b-ee-blue-2" : 'text-ee-grey-2',
                    )} 
                    onClick={()=>setPath('change')}
                >
                    Change Balance Amount
                </p>
                <p 
                    className={clsx(
                        "py-2.5 px-[20px] text-center font-semibold cursor-pointer",
                        path==="freeze" ? "text-ee-blue-2 bg-ee-blue-1 border-b-[3px] border-b-ee-blue-2" : 'text-ee-grey-2',
                    )} 
                    onClick={()=>setPath('freeze')} 
                >
                    Freeze Collections
                </p>
                <p 
                    className={clsx(
                        "py-2.5 px-[20px] text-center font-semibold cursor-pointer",
                        path==="skip" ? "text-ee-blue-2 bg-ee-blue-1 border-b-[3px] border-b-ee-blue-2" : 'text-ee-grey-2',
                    )} 
                    onClick={()=>setPath('skip')}
                >
                    Skip Collections
                </p>
            </div>
            <Table
                tableTop={
                    <div>
                        <div className="px-2.5 py-1.5 rounded-lg font-semibold text-ee-grey-4 bg-ee-grey-5 border-[1px] border-ee-grey-3 w-[160px] text-center">Float Patch Activities </div>
                        {/* search box */}
                    </div>
                }
                data={
                    path==="all"?patches.allpatch
                    : path==="change"?patches.changeBalance
                    : path==="freeze"?patches.freezeCollection
                    : path==="skip"?patches.skipCollection
                    : []
                }
                theadClass="bg-ee-grey-5 font-medium border-l-[2px] border-solid border-cc-grey-6"
                tdataClass="border-l-[1px] border-solid border-cc-grey-5 py-2.5 pl-[20px] text-sm"
                rows={[
                    {
                        header:'Business Name',
                        view: (item) => (
                            <span>
                                {item?.businessId?.businessName}
                            </span>
                        ),
                    },
                    {
                        header:'RC/BV Number',
                        view: (item) => (
                            <span>
                                {item?.businessId?.CAC || item?.businessId?.bvn}
                            </span>
                        ),
                    },
                    {
                        header:'Patch Option',
                        view: (item) => (
                            <span>
                                {
                                    item?.category==="changeBalance" ? "Change Balance Ammount"
                                    : item?.category==="freezeCollection" ? "Freeze Collections"
                                    : item?.category==="skipCollection" ? "Skip Collections"
                                    : item?.category
                                }
                            </span>
                        ),
                    },
                    {
                        header:'Request Date',
                        view: (item) => (
                            <span>{dateFormatter(item.createdAt, true)}</span>
                        ),
                    },
                    {
                        header:'Status',
                        view: (item) => (
                            <span>
                                <Status
                                    type={
                                        item.status==="inprogress"?"pending"
                                        : item.status==="rejected"?"fail"
                                        : item.status==="pending"?"pending"
                                        : "success"
                                    }
                                    text={
                                        item.status==="inprogress"?"In Progress"
                                        : item.status==="rejected"?"Rejected"
                                        : item.status==="pending"?"Pending"
                                        : "Successful"
                                    }
                                />
                            </span>
                        ),
                    },
                    {
                        header:'Action',
                        view: (item) => (
                            <button 
                                onClick={()=>navigate(`/float/patch/details/${item._id}`)}
                                className="border-[0px] bg-ee-primary text-white font-semibold px-6 py-2 rounded-[6px]"
                            >
                                View Request
                            </button>
                        ),
                    },
                ]}
            />
        </div>
    )
}