import React, { useEffect } from 'react';
import { MetricsService } from 'services/metrics.service';
import metricsIcon from "../../../images/svg/box/tech.svg";
import Filter from 'components/shared/Filter';
import selectArrow from "images/svg/select-arrow.svg";
import useSingleState from 'hooks/useSingleState';
import { handleRequestErrors } from 'utils/helpers';
import { useSearchParams } from 'react-router-dom';
import DataBox from 'components/shared/DataBox';


const Metrics = () => {
    const [searchParams] = useSearchParams();
    const query = searchParams.get("filter") || "this_month"
    const startDate = searchParams.get("customStart")||""
    const endDate = searchParams.get("customEnd")||""
    const metricsData = useSingleState(null)

    useEffect(() => {
        MetricsService.getMetrics({
            type: query,
            start: startDate,
            end: endDate,
        })
            .then(response => response.data)
            .then((res) => {
                if (res.status==='success') {
                    metricsData.set(res.data)
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    const filterOptions = [
        { label: "Last 7 days", value: "last_seven_days"},
        { label: "This Month", value: "this_month"},
        { label: "Last Month", value: "last_month"},
        { label: "Last 3 Months", value: "last_three_months"},
        { label: "Last 6 Months", value: "last_six_months"},
        { label: "Last 12 Months", value: "last_twelve_months"},
        { label: "Custom", value: "custom"},
    ]

    return (
        <div className='mt-10'>
            <div className='flex justify-end mb-10'>
                <Filter
                    placeHolder="This Month"
                    options={
                        filterOptions.map((item) => ({
                            label: item.label,
                            value: item.value
                        }))
                    }
                    containerClass="border-ee-primary text-base text-[#8A8A8A] flex-row-reverse"
                    icon={selectArrow}
                    iconClassName="!w-[10px]"
                    optionItemClass="text-sm text-xs font-semibold p-2 rounded-md m-0 hover:bg-ee-grey-3"
                />
            </div>
            <div className='grid grid-cols-4 gap-[20px] box-border'>
                {
                    [
                        {
                            title: "Active Businesses",
                            value: metricsData?.get?.activeBusinesses||0,
                        },
                        {
                            title: "Churn Rate",
                            value: `${metricsData?.get?.churnRate?.toFixed(1)||0}%`,
                        },
                        {
                            title: "Churned Businesses",
                            value: metricsData?.get?.churnedBusinesses||0,
                        },
                        {
                            title: "Daily Active Users",
                            value: metricsData?.get?.dailyActiveUsers||0,
                        },
                        {
                            title: "Inactive Businesses",
                            value: metricsData?.get?.inactiveBusinesses||0,
                        },
                        {
                            title: "Monthly Active Users",
                            value: metricsData?.get?.monthlyActiveUsers||0,
                        },
                        {
                            title: "Retention Rate",
                            value: `${metricsData?.get?.retentionRate?.toFixed(1)||0}%`,
                        },
                        {
                            title: "Total Businesses",
                            value: metricsData?.get?.totalBusinesses||0,
                        },
                        {
                            title: "Weekly Active Users",
                            value: metricsData?.get?.weeklyActiveUsers||0,
                        },
                        {
                            title: "Adoption Rate",
                            value: `${metricsData?.get?.adoptionRate?.toFixed(1)||0}%`,
                        },
                    ].map(item => (
                        <div key={item.title}>
                            <DataBox
                                title={item.title}
                                icon={<img src={metricsIcon} alt={item.title} className='w-[20px]' />}
                                value={item.value}
                                className="!shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]"
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Metrics;