import { APIKit, loadToken } from 'services';


export const MetricsService = {
    getMetrics: (args) => {
        return APIKit(10000).get(`eagleeyeauth/business/metrics`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },
}