import LineGraph from "./LineGraph";
import Filter from "components/shared/Filter";
import { currencyFormatter, dateFormatter, handleRequestErrors } from "utils/helpers";
import Table from "components/Table";
import viewmore_dropdown from "images/svg/recova/viewmore_dropdown.svg";
import cards from "images/svg/recova/cards.svg";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { RecovaService } from "services/recova.service";
import payoutAction from "images/svg/payoutAction.svg";
import WarningModal from "components/Modal/WarningModal";
import { toast } from "react-toastify";


export default function PayoutSection () {
    const [dropDownView, setDropDownView] = useState(null)
    const [data, setData] = useState(null)
    const [graphData, setGraphData] = useState([])
    const [tablePath, setTablePath] = useState("pending")
    const [actionView, setactionView] = useState(null)
    const [showWarning, setShowWarning] = useState(false)
    const [warningType, setWarningType] = useState("");
    const [approvalLoading, setApprovalLoading] = useState(false)

    useEffect(()=>{
        loadPayout()
    }, [])

    const loadPayout = () => {
        RecovaService.getPayoutSelfServiceActivity()
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                setData(res.data)
                const formattedGraphData = Object.entries(res.data.graphData).map(([daysRange, count]) => ({
                  daysRange,
                  count
                }));
                setGraphData(formattedGraphData)
            })
            .catch((err) => {
                handleRequestErrors(err)
            })
    }

    const handleApproval = () => {
        const adminName = JSON.parse(sessionStorage.getItem('adminName'));
        setApprovalLoading(true)
        if(warningType==="approve"){
            const payload = JSON.stringify({
                requestId: actionView._id || "",
                approvedBy: adminName || ""
            })
            RecovaService.approvePayout(payload)
                .then((response) => response.data)
                .then((res) => {
                    setApprovalLoading(false)
                    if(res.status){
                        loadPayout()
                        setShowWarning(false)
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    setApprovalLoading(false)
                    handleRequestErrors(err)
                })
        } else {
            const payload = JSON.stringify({
                requestId: actionView._id || "",
                rejectedBy: adminName || ""
            })
            RecovaService.rejectPayout(payload)
                .then((response) => response.data)
                .then((res) => {
                    setApprovalLoading(false)
                    if(res.status){
                        loadPayout()
                        setShowWarning(false)
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    setApprovalLoading(false)
                    handleRequestErrors(err)
                })
        }
    }

    return (
        <div>
            <div>
                <h1 className="text-2xl font-bold">Payouts</h1>
                <div className="text-base text-[#7E7E7E] my-7 font-medium flex items-center gap-x-6">
                    <p className={clsx("pb-1", true && "border-b-[2px] border-b-ee-primary")}>Self Service</p>
                    <p className={clsx("pb-1", false && "border-b-[2px] border-b-ee-primary")}>Automated</p>
                </div>
                <div className="flex justify-center items-center gap-x-10 mb-8">
                    <div className="flex gap-x-2.5 items-center">
                        <img src={cards} alt="" className="w-[50px]" />
                        <div>
                            <p className="text-base font-bold">{data?.noOfPayouts || 0}</p>
                            <p className="text--ee-grey-1">Total Payout Received</p>
                        </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                        <img src={cards} alt="" className="w-[50px]" />
                        <div>
                            <p className="text-base font-bold">{currencyFormatter((data?.valueOfAcceptedPayouts+data?.valueOfPendingPayouts)||0)}</p>
                            <p className="text--ee-grey-1">Value of Payout Received</p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <LineGraph data={graphData} graphFor="payout" cartesianGridType="dottedXonly"/>
                </div>
                <div className="my-10">
                    <div className="text-base text-ee-grey-2 mt-6 font-semibold flex items-center gap-x-6">
                        {
                            ["pending", "approved", "rejected"].map((item, idx)=>(
                                <p 
                                    key={idx}
                                    className={clsx(
                                        "py-1.5 px-2.5 capitalize cursor-pointer", 
                                        tablePath===item && "border-b-[2px] border-b-ee-primary text-ee-primary bg-[#E5EEFF]"
                                    )}
                                    onClick={()=>setTablePath(item)}
                                >
                                    {item}
                                </p>
                            ))
                        }
                    </div>
                    <Table
                        tableTitle={"Payout Activities"}
                        hideSearch={false}
                        data={
                            tablePath==="pending" ? (data?.pendingPayouts||[])
                            : tablePath==="rejected" ? (data?.rejectedPayouts||[])
                            : (data?.approvedPayouts||[])
                        }
                        searchPlaceHolder="Search Business Name..."
                        slot={
                            <div>
                            <Filter 
                                options={
                                    ["Value"].map((item) => ({
                                        label: item,
                                        value: item
                                    }))
                                } 
                            />
                            </div>
                        }
                        theadClass="font-semibold text-[#667085] text-xs"
                        tdataClass="py-2.5 pl-[10px] text-sm"
                        rows={[
                            {
                                header:'Business Name',
                                view: (item) => (
                                    <span>
                                        {item.businessId.businessName}
                                    </span>
                                ),
                            },
                            {
                                header:'Name of Customer',
                                view: (item) => (
                                    <span>
                                        {item.toClient}
                                    </span>
                                ),
                            },
                            {
                                header:'Amount',
                                view: (item) => (
                                    <span>
                                        {currencyFormatter(item.amount || 0)}
                                    </span>
                                ),
                            },
                            {
                                header:'Date created',
                                view: (item) => (
                                    <span>{dateFormatter(item.createdAt, true)}</span>
                                ),
                            },
                            {
                                header:'Mode of Settlement',
                                view: (item) => (
                                    <span>Self Service</span>
                                ),
                            },
                            {
                                header:tablePath==="pending" ? 'Action':"",
                                view: (item) => (
                                    tablePath==="pending" ?
                                    <div className="relative">
                                        <img 
                                            src={payoutAction} 
                                            alt="action" 
                                            className="w-6 cursor-pointer" 
                                            onClick={()=>{
                                                if(!!actionView){
                                                    setactionView(null)
                                                } else {
                                                    setactionView(item)
                                                }
                                            }}
                                        />
                                        {
                                            actionView===item &&
                                            <div onClick={()=>setShowWarning(true)} className="cursor-pointer text-sm font-medium absolute left-0">
                                                <p onClick={()=>setWarningType("approve")} className="bg-[#ECFDF3] text-[#027A48] px-2.5 py-1.5">Approve</p>
                                                <p onClick={()=>setWarningType("reject")} className="bg-[#FEF3F2] text-[#F04438] px-2.5 py-1.5">Reject</p>
                                            </div>
                                        }
                                    </div>
                                    : null
                                ),
                            },
                            {
                                header: "",
                                view: (item) => (
                                    <div>
                                        <img 
                                            src={viewmore_dropdown} 
                                            alt="" 
                                            onClick={()=>{
                                                if(!!dropDownView){
                                                    setDropDownView(null)
                                                } else {
                                                    setDropDownView(item)
                                                }
                                            }}
                                            className={clsx(
                                                "w-8 cursor-pointer transition-all",
                                                item===dropDownView ? "rotate-180":"rotate-0",
                                            )}
                                        />
                                        {
                                            item===dropDownView &&
                                            <div className="absolute w-full left-0 z-10">
                                                <div className="shadow-md bg-white rounded mx-6 my-[10px] border-[1px] text-sm border-[#0349FD] p-[20px]">
                                                    <div className="grid grid-cols-5 gap-[20px]">
                                                        <div>
                                                            <p className="text-[#667085]">Customer Name</p>
                                                            <p>{item.toClient}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#667085]">Amount</p>
                                                            <p>{currencyFormatter(item.amount || 0)}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#667085]">Date created</p>
                                                            <p>{dateFormatter(item.createdAt, true)}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#667085]">Settlement mode</p>
                                                            <p>Self Service</p>
                                                        </div>
                                                        {/* <div>
                                                            <p className="text-[#667085]">Payout Location</p>
                                                            <p>Value</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#667085]">e-Wallet Number</p>
                                                            <p>Value</p>
                                                        </div> */}
                                                        <div>
                                                            <p className="text-[#667085]">Status</p>
                                                            <p>{item.approvalStatus}</p>
                                                        </div>
                                                    </div>
                                                    <p className="font-semibold text-sm mt-6 mb-1.5">Withdrawal Beneficiary Details</p>
                                                    <div className="grid grid-cols-5 gap-[20px]">
                                                        <div>
                                                            <p className="text-[#667085]">Account Name</p>
                                                            <p>{item.toClient}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#667085]">Account Number</p>
                                                            <p>{item.toAccount}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#667085]">Bank</p>
                                                            <p>{item.toBank}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#667085]">Requested By</p>
                                                            <p
                                                                className={clsx(
                                                                    !item?.requestedBy?.firstName && "!lowercase"
                                                                )}
                                                            >
                                                                {
                                                                    !!item?.requestedBy?.firstName ?
                                                                    `${item?.requestedBy?.firstName} ${item?.requestedBy?.lastName}`
                                                                    : item?.requestedBy?.email
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            },
                        ]}
                    />
                    <WarningModal
                        show={showWarning}
                        isLoading={approvalLoading}
                        handleClose={()=>setShowWarning(false)}
                        handleAction={()=>handleApproval(warningType)}
                        title={`${warningType==="reject"?"Reject":"Approve"} Payout`}
                        text={`Are you sure you want to ${warningType} this request? This action cannot be undone.`}
                        type={warningType==="reject"?"warning":""}
                    />
                </div>
          </div>
        </div>
    )
}