import {Row, Col} from "react-bootstrap";
import Back from "../../../back";
import { useNavigate } from "react-router-dom";

import info from "../../../../images/svg/float/info.svg";
import { useEffect } from "react";


export default function BVNResult ({setHeader}) {
    const navigate = useNavigate();
    
    let bvnRes = {};
    try {
        bvnRes = JSON.parse(sessionStorage.getItem('floatBvn'));
    } catch (err) {
        //err
    } 
    console.log(bvnRes)

    useEffect(() => {
        setHeader()
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
      

    return(
        <div className="home_main">
            <div style={{margin:"-10px 0 20px"}}>
             <Back to={()=> navigate(-1)} />
            </div>
            <div className="identity_result">
                <div className="identity_result-top">
                    <div className="identity_result-top_image">
                        <img src={bvnRes?.photo} alt="" />
                    </div>
                    <div>
                        <h5>{bvnRes?.firstName} {bvnRes?.middleName} {bvnRes?.lastName}</h5>
                        <p>BVN: {bvnRes?.bvn}</p>
                    </div>
                    <img src={info} alt="" className="identity_result-top_button" />
                </div>
                <hr/>
                <Row>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">NIN</p>
                            <p className="text2">{bvnRes?.nin===""?"Not available":bvnRes.nin}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Date of Birth</p>
                            <p className="text2">{bvnRes?.dateOfBirth===""?"Not available":bvnRes.dateOfBirth}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Gender</p>
                            <p className="text2">{bvnRes?.gender===""?"Not available":bvnRes.gender}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Marital Status</p>
                            <p className="text2">{bvnRes?.maritalStatus===""?"Not available":bvnRes.maritalStatus}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Nationality</p>
                            <p className="text2">{bvnRes?.nationality===""?"Not available":bvnRes.nationality}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Watchlisted</p>
                            <p className="text2">{bvnRes?.watchListed===""?"Not available":bvnRes.watchListed}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Phone Numbers</p>
                            {   bvnRes?.phones?.length>0 ?
                                bvnRes?.phones.map((item)=>(
                                    <p className="text2" key={item} style={{margin: "0"}}>{item}</p>
                                ))
                                :
                                <p className="text2">Not available</p>
                            }
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Email</p>
                            <p className="text3" style={{textTransform:"lowercase"}}>{bvnRes?.email===""?"Not available":bvnRes.email}</p>
                        </div>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Registered On</p>
                            <p className="text2">{bvnRes?.registrationDate===""?"Not available":bvnRes.registrationDate}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Original Location</p>
                            <p className="text2">{bvnRes?.lgaOfOrigin===""?"Not available":bvnRes.lgaOfOrigin}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Name On Card</p>
                            <p className="text2">{bvnRes?.nameOnCard===""?"Not available":bvnRes.nameOnCard}</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="identity_result-box1">
                            <p className="text1">Residential Address</p>
                            <p className="text2">{bvnRes?.residentialAddress===""?"Not available":bvnRes.residentialAddress}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}