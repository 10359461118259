import React, { useEffect, useState } from 'react';
import {Routes, Route} from "react-router-dom";
import Details from './Details';
import Main from './Main';
import Request from '../../../components/Dashboard/Float/components/Request';
import OfferDetails from '../../../components/Dashboard/Float/components/OfferDetails';



function SalesOps ({setDetailName, roles, adminRole}) {
    const [header, setHeader] = useState(true);

    console.log(header)
    useEffect(() => {
        setDetailName("");
    }, [setDetailName]);

  return (
      <div>
            <Routes>
                <Route path="/" element={<Main />}/>
                <Route path="/:id" element={<Details />} />

                {/* For sales people to see business float details  */}
                <Route path="/request/:id" element={<Request setHeader={()=> setHeader(false)} roles={roles} adminRole={adminRole} />} />
                <Route path="/details" element={<OfferDetails setHeader={()=> setHeader(false)} />} />
            </Routes>
      </div>
  )
}



export default SalesOps