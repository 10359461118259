import FilePreview from "components/shared/FilePreview";
import { FaCheck } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { getInitials } from "utils/helpers";


export default function DetailsBox ({
    businessData, 
    acceptedTerms=false
}) {
    return (
        <div className="border-[0.25px] border-[#0046E6] box-border rounded-[10px] max-w-[590px] p-9 my-10 shadow-[6px_4px_38px_4px_rgba(0,0,0,0.02)]">
            <div className="flex items-center gap-2 mb-10">
                <div className="w-[50px] h-[50px] rounded-full bg-[#CE06E0] flex items-center justify-center">
                    <p className="text-white font-bold text-[30px] uppercase">{getInitials(`${businessData?.businessName}`)}</p>
                </div>
                <h3 className="text-[30px] font-bold">{businessData?.businessName}</h3>
            </div>
            <div className="grid grid-cols-2 gap-y-8 gap-x-[20px]">
                {
                    [
                        {label: "Business Name", value: businessData?.businessName||""},
                        {label: "RC Number", value: businessData?.CAC||"Nil"},
                        {label: "Business Owner", value: businessData?.ownerFullName||"Nil"},
                        {label: "Business Sector", value: businessData?.businessSector||""},
                        {label: "BVN", value: businessData?.bvn||"Nil"},
                        {label: "NIN", value: businessData?.nin||"Nil"},
                        {label: "Phone", value: businessData?.phoneNumber||""},
                        {label: "Email", value: businessData?.email||""},
                        {label: "Size", value: businessData?.size||"Nil"},
                        {label: "Registration Type", value: businessData?.businessType||""},
                        {label: "Country", value: businessData?.country||""},
                        {label: "State", value: businessData?.state||""},
                        {label: "LGA", value: businessData?.lga||""},
                        {label: "Address", value: businessData?.address||""},
                        {label: "Compliance status", value: businessData?.isComplied === true ? 'True' : "False"},
                        {label: "Website", value: businessData?.website||"Nil"},
                    ].map((item, i)=>(
                        <div key={i}>
                            <p className="text-base text-[#666666]">{item.label}</p>
                            <p className="font-medium text-[21px] pb-2.5 border-b border-b-[#6795FF]">{item.value}</p>
                        </div>
                    ))
                }
            </div>
            <div className="max-w-[400px] mt-10">
                {
                    !!businessData?.regFile &&
                    <div>
                        <p className="text-[21px] font-medium">CAC Document</p>
                        <FilePreview
                            name={`${businessData?.businessName}.pdf`} 
                            additionalName="Incorporation doc" 
                            downloadLink={businessData?.regFile||""}
                        />
                    </div>
                }
                {
                    !!businessData?.idFile &&
                    <div>
                        <p className="text-[21px] font-medium mt-6">Identity Document</p>
                        <FilePreview
                            name={`${businessData?.businessName}.pdf`} 
                            additionalName={
                                businessData?.idType==="passport" ? "International Passport" 
                                : businessData?.idType==="drivers license" ? "Drivers License" 
                                : businessData?.idType==="voters card" ? "Voter’s Card" 
                                : businessData?.idType==="national id" ? "National Identity Card" 
                                : ""
                            }
                            downloadLink={businessData?.idFile||""}
                        />
                    </div>
                }
            </div>
            {
                !!acceptedTerms &&
                <label className="flex items-center gap-1.5">
                    <div 
                        className="border-[1px] w-[14px] h-[14px] rounded border-[#666666] flex items-center justify-center"
                        data-tooltip-id="my-tooltip" 
                        data-tooltip-content={acceptedTerms?"Spectrum terms and conditions accepted":"Spectrum terms and conditions not accepted"}
                    >
                        {acceptedTerms && <FaCheck className="text-[#47D800] w-2.5"/>}
                    </div>
                    <p className="text-base font-medium text-[#666666]">Terms and Conditions</p>
                </label>
            }
            <Tooltip id="my-tooltip" place="left" />
        </div>
    )
}