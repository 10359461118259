import React, { useEffect, useState } from 'react';
import {Container} from "react-bootstrap";
import {Routes, Route, Link} from "react-router-dom";
// import Details from './Details';
import Main from './Main';
import home from '../../../images/svg/float/home.svg'
import paymentHistory from '../../../images/svg/float/paymentHistory.svg'
import dollarIcon from '../../../images/svg/float/dollarIcon.svg'
import editPen from '../../../images/svg/float/edit_pen.svg'
import PaymentHistory from './PaymentHistory';
import Request from './components/Request';
import Offer from './components/Offer';
import RCResult from './results/RCResult';
import Director1BVNResult from './results/Director1BVNResult';
import Director2BVNResult from './results/Director2BVNResult';
import OfferDetails from './components/OfferDetails';
import MainUSD from './MainUSD';
import ExchangeRate from './ExchangeRate';
import UpcomingPayment from './UpcomingPayment';
import PatchMain from "./Patch";
import PatchDetails from './Patch/details';

function Float ({setDetailName, roles, adminRole}) {
    const [requestPath, setRequestPath] = useState("home");
    const [header, setHeader] = useState(true);

    useEffect(() => {
        setDetailName("");
      }, [setDetailName]);

    return (
        <div className="settings">
            <Container>
                {
                    header &&
                    <div className='float_top'>
                        <Link to='/float' >
                            <div 
                                className={requestPath==="home"?"float_top-toggle active":'float_top-toggle'}
                                onClick={()=> { 
                                    setRequestPath("home")
                                }}
                            >
                                <img src={home} alt="icon" />
                                <p> Home (Naira) </p>
                                {/* <p> Home (₦) </p> */}
                            </div>
                        
                        </Link>
                        <Link to='/float/usd' >
                            <div 
                                className={requestPath==="usd"?"float_top-toggle active":'float_top-toggle'}
                                style={{marginLeft: '1.5em'}}
                                onClick={()=> { 
                                    setRequestPath("usd")
                                }}
                            >
                                <img src={home} alt="icon" />
                                <p> Home (USD) </p>
                            </div>
                        
                        </Link>
                        
                        <Link to='/float/upcoming-payment'>
                            <div 
                                className={requestPath==="upcoming-payment"?"float_top-toggle active":'float_top-toggle'}  
                                style={{marginLeft: '1.5em'}}
                                onClick={()=> { 
                                    setRequestPath("upcoming-payment")
                                }}
                                >
                                <img src={paymentHistory} alt="icon" />
                                <p> Upcoming Payments </p>
                            </div>
                        </Link>

                        <Link to='/float/payment-history'>
                            <div 
                                className={requestPath==="payment-history"?"float_top-toggle active":'float_top-toggle'}  
                                style={{marginLeft: '1.5em'}}
                                onClick={()=> { 
                                    setRequestPath("payment-history")
                                }}
                                >
                                <img src={paymentHistory} alt="icon" />
                                <p> Payment History </p>
                            </div>
                        </Link>
                        
                        <Link to='/float/exchange-rate'>
                            <div 
                                className={requestPath==="exchange-rate"?"float_top-toggle active":'float_top-toggle'}  
                                style={{marginLeft: '1.5em'}}
                                onClick={()=> { 
                                    setRequestPath("exchange-rate")
                                }}
                                >
                                <img src={dollarIcon} alt="icon" />
                                <p> Exchange Rate </p>
                            </div>
                        </Link>
                        
                        <Link to='/float/patch'>
                            <div 
                                className={requestPath==="patch"?"float_top-toggle active":'float_top-toggle'}  
                                style={{marginLeft: '1.5em'}}
                                onClick={()=> { 
                                    setRequestPath("patch")
                                }}
                                >
                                <img src={editPen} alt="icon" />
                                <p> Float Patch </p>
                            </div>
                        </Link>
                        
                    </div>
                }
                <Routes>
                    <Route path="/" element={<Main setHeader={()=> setHeader(true)}  />}/>
                    <Route path="/usd" element={<MainUSD setHeader={()=> setHeader(true)}  />}/>
                    <Route path="/payment-history" element={<PaymentHistory setHeader={()=> setHeader(true)}  />} />
                    <Route path="/upcoming-payment" element={<UpcomingPayment setHeader={()=> setHeader(true)} />} />
                    <Route path="/exchange-rate" element={<ExchangeRate setHeader={()=> setHeader(true)} />} />
                    <Route path="/request/:id" element={<Request setHeader={()=> setHeader(false)} roles={roles} adminRole={adminRole} />} />
                    <Route path="/offer" element={<Offer setHeader={()=> setHeader(false)}/>} />
                    <Route path="/details" element={<OfferDetails setHeader={()=> setHeader(false)}/>} />
                    <Route path="/cac" element={<RCResult setHeader={()=> setHeader(false)}/>} />
                    <Route path="/director1bvn" element={<Director1BVNResult setHeader={()=> setHeader(false)}/>} />
                    <Route path="/director2bvn" element={<Director2BVNResult setHeader={()=> setHeader(false)}/>} />
                    <Route path="/patch" element={<PatchMain setHeader={()=> setHeader(true)}  />} />
                    <Route path="/patch/details/:id" element={<PatchDetails setHeader={()=> setHeader(false)} />} />
                </Routes>
            </Container>
        </div>
    )
}

export default Float