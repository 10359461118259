
import { useEffect, useState } from "react";
import Back from '../../../back'
import view from '../../../../images/svg/float/view.svg'
import document from '../../../../images/svg/float/document.svg'
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import BasicVerificationResult from '../results/BasicVerificationResult'
import { changeFloatStatus, declineFloatOffer, floatDirectorConsent, getAllBankLogo, sendDisburseMail, viewFloatRequest } from '../../../../lib/auth';
import { toast } from 'react-toastify';
import Modal from "../../../Modal";
import clsx from "clsx";
import loader from "images/gif/white-loader.gif";
import { OtherService } from "services/other.services";


function Request ({setHeader, roles, adminRole}) {
    let navigate = useNavigate();
    const location = useLocation().pathname
    const months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [basicData, setBasicData] = useState({});
    const [basicVerificationModal, setBasicVerificationModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [pendingShowModal, setPendingShowModal] = useState(false);
    const [disbursedShowModal, setDisbursedShowModal] = useState(false);
    const [ngBanks, setNgBanks] = useState([]);
    const [requestData, setRequestData] = useState([]);
    const [ipDeets, setIpDeets] = useState({
        country_name: "Nigeria",
        IPv4: "102.89.45.161"
      });
    let params = useParams()
    const [resendLoading, setResendLoading] = useState({
        director1: false,
        director2: false
    })

    useEffect(() => {
      setHeader();
      getAllBankLogo()
        .then(resp=>{
            setNgBanks(resp.data);
        })
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect( () => {
        OtherService.getGeolocation()
            .then(response => response.data)
            .then((res) => {
                setIpDeets(res)
            })
            .catch((err)=>{
                // console.log(err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        viewFloatRequest(params?.id)
        .then(response => response.data)
        .then((res) => {
          if(res.status === 'success'){
            sessionStorage.setItem( "requestDetails", JSON.stringify({ requestData: res.data }));
            setRequestData(res.data )
          }
          else {
            toast.error("Something went wrong, please try again!!!")
        }
      })
      .catch((err) => {
          if(err.response){
            toast.error(err.response.data.message);
          } else{
            toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
          }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    var formatMoney = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        //minimumFractionDigits: 0, 
        maximumFractionDigits: 2, 
      });

      const openView = (item) => {
          if(item === 'businessBankDetail'){
            setBasicData(requestData?.businessBankDetail)
            setBasicVerificationModal(true)
          }else if(item === 'Director1BankResult'){
            setBasicData(requestData?.director1BankDetail)
            setBasicVerificationModal(true)
          }else if(item === 'Director2BankResult'){
            setBasicData(requestData?.director2BankDetail)
            setBasicVerificationModal(true)
          }
      }

      const director1Bvn = (item) => {
          sessionStorage.setItem('floatBvn', JSON.stringify(requestData?.director1BVN));
          window.location= '/float/director1bvn'
      }

      const director2Bvn = (item) => {
          sessionStorage.setItem('floatBvn', JSON.stringify(requestData?.director2BVN));
          window.location= '/float/director2bvn'
      }

      const handleDecline = () => {
        declineFloatOffer(requestData?._id)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
                toast.success('Float Offer Rejected')
                setTimeout(() => {
                    navigate('/float')
                }, 1500);
            } else {
                toast.error("Something went wrong, please try again!!!")
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
            } else{
                toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            }
        });
      }

      const onchangeFloatStatus = () => {
        let payload = {
            id: requestData?._id,
            status: "pending" ,
          }
        changeFloatStatus(payload, ipDeets)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
                toast.success('Successful')
                setTimeout(() => {
                    navigate('/float')
                }, 1500);
            } else {
                toast.error("Something went wrong, please try again!!!")
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
            } else{
                toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            }
        });
      }

      const handleDisbursedFloat = () => {
        let payload = {
            id: requestData?._id,
            businessName: requestData?.businessId?.businessName,
            amount: requestData?.approvedFloat?.approvedAmount,
            accountNumber: requestData?.bankAccountNumber,
            bankName: requestData?.bankName,
            email: requestData.businessId.email
          }
        sendDisburseMail(payload)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
                toast.success('Disburse mail sent successfully')
                setTimeout(() => {
                    navigate('/float')
                }, 1500);
            } else {
                toast.error("Something went wrong, please try again!!!")
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
            } else{
                toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            }
        });
      }

      const resendDirectorEmail = (path) => {
        let payload = {
            businessName: requestData?.businessId?.businessName,
            floatId: requestData?._id,
            rcNumber: requestData?.rcNumber,
            businessEmail: requestData?.businessId?.email,
            amount: requestData?.loanAmount,
            directorType: path,
            directorBVN: path === 'director1' ? requestData?.director1?.bvn : requestData.director2?.bvn,
            directorBankName: path === 'director1' ? requestData.director1?.bankName : requestData.director2?.bankName,
            directorPosition: path === 'director1' ? requestData.director1?.position : requestData.director2?.position,
            directorPhoneNumber: path === 'director1' ? requestData.director1?.phone : requestData.director2?.phone,
            directorEmail: path === 'director1' ? requestData.director1?.email : requestData.director2?.email,
            directorFullname: path === 'director1' ? requestData.director1?.fullName : requestData.director2?.fullName,
            directorBankAccountNumber: path === 'director1' ? requestData.director1?.bankAccountNumber : requestData.director2?.bankAccountNumber, 
        }
        if(path === 'director1'){
            setResendLoading({...resendLoading, director1: true})
            floatDirectorConsent(payload)
            .then((response) => response.data)
            .then((res) => {
                setResendLoading({...resendLoading, director1: false})
                if (res.status === 'success') {
                    toast.success('Consent email sent')
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setResendLoading({...resendLoading, director1: false})
                if(err.response){
                    toast.error(err.response.data.message);
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                }
                }
            });
        } 
        if(path === 'director2'){
            setResendLoading({...resendLoading, director2: true})
            floatDirectorConsent(payload)
            .then((response) => response.data)
            .then((res) => {
                setResendLoading({...resendLoading, director2: false})
                if (res.status === 'success') {
                    toast.success( 'Consent email sent')
                } else {
                    toast.error(res.message) 
                }
            })
            .catch((err) => {
                setResendLoading({...resendLoading, director2: false})
                if(err.response){
                    toast.error(err.response.data.message);
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                    // sessionStorage.setItem('floatVerification', JSON.stringify({acc:'failed'}))
                    
                }
                }
            });
        }
    }

    const goBack = () => {
        if(location.includes('sales-ops')){
            // when user comes from sales-ops section
            navigate(`/sales-ops/${requestData?.businessId?._id}`)
        } else {
            // when user comes directly from float
            navigate(`/float`)
        }
    }

    const isConsent = () => {
        if(requestData?.director1Consent === false || requestData?.director2Consent === false ){
            toast.error("Remind the business to give director consent to the float!!!", {
                className: "toast.error"
            })
            return false
        }else{
            navigate('/float/offer')
        }
    }

    return (
        <div className="home_main">
            <div className="accounts">
            <Back to={()=>goBack()} />
               <div style={{margin: '2em 0', borderBottom: '1px solid #DFDBDB', paddingBottom: '1em' }}>
                <h5>Float Request</h5>
            </div>
            </div>

            <div className="float_request">
                <div className="float_request-top">
                    <div>
                        <h3>{requestData?.businessId?.businessName}</h3>
                        <p>RC Number: {requestData?.rcNumber} <span><Link to='/float/cac'>View</Link></span></p>
                    </div>
                    <div>
                        {
                            requestData.createdAt &&
                            <p style={{fontWeight: 600}} >
                                Applied on: {new Date(requestData.createdAt).getDate()} {months[new Date(requestData.createdAt).getMonth()]} {new Date(requestData.createdAt).getFullYear()}
                                {" "} <span>@{new Date(requestData?.createdAt)?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</span>
                            </p>
                        }
                        {
                            requestData.acceptedAt &&
                            <p style={{fontWeight: 600}}>
                                Accepted on: {new Date(requestData.acceptedAt).getDate()} {months[new Date(requestData.acceptedAt).getMonth()]} {new Date(requestData.acceptedAt).getFullYear()}
                               {" "} <span>@{new Date(requestData?.acceptedAt)?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</span>
                            </p>
                        }
                    </div>
                   
                    
                </div>

                <div className="float_request-loan">
                    <div className="amount">
                        <p>Loan Amount Requested</p>
                        <h3> {requestData?.loanAmount && formatMoney.format(requestData?.loanAmount)}</h3>
                    </div>
                    <div className="duration">
                        <p>Loan Duration</p>
                        <h3> {requestData?.loanDuration} </h3>
                    </div>
                </div>

                <div className="float_request-statement">
                    <h4>Bank Statement</h4>
                    <div style={{display:'flex', alignItems: 'center'}}>
                        <div className="box">
                            <img src={document} alt="document icon" />
                            <div>
                                <p>PDf Document</p>
                            </div>
                            <img className="view" src={view} alt="view icon" onClick={()=> window.location.href = requestData?.pdfStatement?.url } />
                        </div>
                        <div className="accountNumber">
                            <h4>Bank Account Number</h4>
                            <p style={{color:'#0046E6', cursor:'pointer'}} onClick={()=>openView('businessBankDetail')}>View</p>
                        </div>
                    </div>
                </div>
                <DirectorInfo
                    data={requestData?.director1}
                    director={1}
                    viewBVN={director1Bvn}
                    viewBankAcc={()=>openView('Director1BankResult')}
                    hasConsent={requestData?.director1Consent}
                    resendConsent={()=>resendDirectorEmail('director1')}
                    address={requestData?.director1Address}
                    resendLoading={resendLoading.director1}
                />
                <DirectorInfo
                    data={requestData?.director2}
                    director={2}
                    viewBVN={director2Bvn}
                    viewBankAcc={()=>openView('Director2BankResult')}
                    hasConsent={requestData?.director2Consent}
                    resendConsent={()=>resendDirectorEmail('director2')}
                    address={requestData?.director2Address}
                    showBottomBorder={true}
                    resendLoading={resendLoading.director1}
                />
                <div className="float_request-action">
                    {
                        requestData?.isApproved === 'pending' && roles?.includes("edit float") ?
                        <div>
                            <button className="decline !w-[185px]" onClick={()=>setShowModal(true)}>Reject Offer</button>
                            <button className="next !w-[185px]" onClick={isConsent}>
                                Next Steps 
                            </button>
                        </div> 
                        :
                        requestData?.isApproved === 'rejected' && roles?.includes("edit float") ?
                        <div>
                            <button className="decline !w-[185px]" onClick={()=>setPendingShowModal(true)}>Make Pending</button>
                        </div>
                        :null
                    }
                    {
                        requestData?.isApproved === 'pending' && roles?.includes("view sales-ops") && adminRole!=='CreditChek Executive' ?
                        <button className="decline !w-[185px]" >Pending</button>:null
                    }
                    {
                        (requestData?.isApproved === 'accepted' || requestData?.isApproved === 'approved') &&
                         roles?.includes("view sales-ops") && adminRole!=='CreditChek Executive'  &&
                        <div>
                            {/* Link to sales-ops/details url when user comes to this page from sales section. */}
                            <Link 
                                to={location.includes('sales-ops')?'/sales-ops/details':'/float/details'}
                            >
                                <button className="next !w-[185px]"> View Offer</button>
                            </Link>
                        </div>
                    }
                    {
                        (requestData?.isApproved === 'accepted' || requestData?.isApproved === 'approved') &&
                        roles?.includes("edit float") &&
                        
                         
                            requestData?.isDisbursed === false ?  
                            <div>
                                <button className="next !w-[185px]" onClick={()=>setDisbursedShowModal(true)}>Trigger Disburse Mail</button>
                                <button className="decline !w-[185px]" onClick={()=>setPendingShowModal(true)}>Make Pending</button>
                                <Link 
                                    to={location.includes('sales-ops')?'/sales-ops/details':'/float/details'}
                                >
                                    <button className="next !w-[185px]"> View Offer</button>
                                </Link>
                            </div>: 
                            requestData?.isDisbursed === true && (requestData?.isApproved === 'accepted' || requestData?.isApproved === 'approved') ?  
                            <Link 
                                to={location.includes('sales-ops')?'/sales-ops/details':'/float/details'}
                            >
                                <button className="next !w-[185px]"> View Offer</button>
                            </Link>
                        :null    
                    }
                </div>
            </div>
            <BasicVerificationResult
                show={basicVerificationModal}
                cancel={()=>setBasicVerificationModal(false)}
                data={basicData}
                ngBanks={ngBanks}
            />
            {showModal && <Modal type={"reject-float"} cancel={()=>setShowModal(false)} doAction={()=> handleDecline()} />}
            {pendingShowModal && <Modal type={"pending-float"} cancel={()=>setPendingShowModal(false)} doAction={()=> onchangeFloatStatus()} />}
            {disbursedShowModal && <Modal type={"disbursed-float"} cancel={()=>setDisbursedShowModal(false)} doAction={()=> handleDisbursedFloat()} />}
        </div>
    )
}


const DirectorInfo = ({
    data, 
    director, 
    viewBVN, 
    viewBankAcc, 
    showBottomBorder=false,
    hasConsent,
    resendConsent,
    resendLoading,
    address,
}) => {
    return (
        <div 
            className={clsx(
                "border-t-[1px] border-t-[#DFDBDB] py-8",
                showBottomBorder && "border-b-[1px] border-b-[#DFDBDB]",
            )}
        >
            <h4 className="mx-0 mt-0 mb-2.5">Director {director} Information</h4>
            <div className="flex flex-wrap gap-x-8 gap-y-4">
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">Full Name</p>
                    <p className="text-base text-[#344054] whitespace-nowrap">{data?.fullName}</p>
                </div>
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">Email Address</p>
                    <p className="text-base text-[#344054]">{data?.email}</p>
                </div>
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">Phone Number</p>
                    <p className="text-base text-[#344054]">{data?.phone || "n/a"}</p>
                </div>
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">Residential Address</p>
                    <p className="text-base text-[#344054] max-w-[300px]">{address || "n/a"}</p>
                </div>
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">BVN</p>
                    <p className="text-base text-[#0046E6] cursor-pointer" onClick={viewBVN}>View</p>
                </div>
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">Bank Account Number</p>
                    <p className="text-base text-[#0046E6] cursor-pointer" onClick={viewBankAcc}>View</p>
                </div>
                <div className="font-medium">
                    <p className="text-sm text-[#667085]">Consent Status</p>
                    <p className="text-base text-[#344054]">{hasConsent ? "True":"False"}</p>
                </div>
                {
                    !hasConsent &&
                    <div 
                        className={clsx(
                            "table-requestBox !h-[22px] mt-3 relative",
                            resendLoading && "opacity-70" 
                        )}
                        onClick={()=>{
                            if(!resendLoading){
                                resendConsent()
                            }
                        }}
                        >
                            Resend Email
                            {
                                resendLoading &&
                                <img src={loader} alt="loader" className="absolute w-[20px] top-1.5 left-[calc(50%-10px)]" />
                            }
                        </div>
                }
            </div>
        </div>
    )
}

export default Request;