import clsx from "clsx";
import file from "images/svg/approvals/file.svg";
import dwnld from "images/svg/approvals/download.svg";
import view from "images/svg/approvals/view.svg";

export default function FilePreview ({
    name, additionalName, hideDownload, downloadLink,
    icon=file
}) {

    return (
        <div className="flex items-center gap-1.5 border-[1px] border-[#D0D5DD] rounded p-2.5 mt-[15px]">
            <img src={icon} alt="" className="w-8" />
            <div className="overflow-hidden">
                <h6 className="text-sm">{name}</h6>
                <p className="capitalize text-xs">{additionalName}</p>
            </div>
            <div className="ml-auto flex gap-[20px]">
                <div 
                    className={clsx(
                        "rounded-lg border-[1px] border-[#D0D5DD] w-[56px] h-[44px] flex items-center justify-center cursor-pointer",
                        hideDownload && "hidden"
                    )}
                >
                    <a href={downloadLink} download target="_blank" rel="noreferrer">
                        <img src={dwnld} alt="" />
                    </a>
                </div>
                <div 
                    className={clsx(
                        "rounded-lg border-[1px] border-[#D0D5DD] w-[56px] h-[44px] flex items-center justify-center cursor-pointer",
                        hideDownload && "hidden"
                    )}
                >
                    <a href={downloadLink} download target="_blank" rel="noreferrer">
                        <img src={view} alt="" />
                    </a>
                </div>
            </div>
        </div>
    )
}