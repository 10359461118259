import React, { useEffect, useState } from 'react'
import Table from '../../../components/Table';
import { useSearchParams } from 'react-router-dom';
import Filter from 'components/shared/Filter';
import selectArrow from "images/svg/select-arrow.svg";
import { WalletService } from 'services/wallet.service';
import { currencyFormatter, formatBigNumber, handleRequestErrors } from 'utils/helpers';
import DataBox from 'components/shared/DataBox';
import walletLightGreen from 'images/svg/box/wallet/walletGreen.svg'
import walletDarkBlue from 'images/svg/box/wallet/walletDarkBlue.svg'
import SearchField from 'components/shared/SearchField';
import Status from 'components/Status';
import dayjs from 'dayjs';
import clsx from 'clsx';
import Tabs from 'components/shared/Tabs';
import CustomBarChart from 'components/Charts/BarChart';
import { mkConfig, generateCsv, download } from "export-to-csv";


const Wallets = ({setDetailName}) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const query = searchParams.get("filter") || "this_month"
  const [walletStats, setWalletStats] = useState(null)
  const [walletFunded, setWalletFunded] = useState(null)
  const search = searchParams.get("search")
  const page = searchParams.get("page") ?? 1
  const tab = searchParams.get("tab") ?? "all"
  const [fundingGraphData, setFundingGraphData] = useState([]);
  const startDate = searchParams.get("customStart")||""
  const endDate = searchParams.get("customEnd")||""

  useEffect(() => {
    setDetailName("");
  }, [setDetailName]);

  const [tableData, setTableData] = useState([])

  var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  var today = new Date();
  var d;
  var month = [];

  for(var i = 12; i > 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month.push(monthNames[d.getMonth()]);
  }

  useEffect(() => {
    getBoxStats()
    getWalletFunded()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const getBoxStats = () => {
    WalletService.walletStats({
      type: query,
      start: startDate,
      end: endDate,
    })
      .then(response => response.data)
      .then((res) => {
        if(res.status === 'success'){
          setWalletStats(res.data)
        }
      })
      .catch((err) => {
        handleRequestErrors(err, true)
      });
  }

  const getWalletFunded = () => {
    WalletService.getTotalFunded({
      type: query,
      start: startDate,
      end: endDate,
    })
      .then(response => response.data)
      .then((res) => {
        if(res.success === true){
          setWalletFunded(res.data)
        }
      })
      .catch((err) => {
        handleRequestErrors(err, true)
      });
  }

  useEffect(() => {
    setIsTableLoading(true)
    WalletService.walletTransaction({
      page, search,
      type: query, 
      path: tab,
      start: startDate,
      end: endDate,
    })
      .then(response => response.data)
      .then((res) => {
        setIsTableLoading(false)
        if(res.status === 'success'){
          setTableData(res.data)
        }
      })
      .catch((err) => {
        setIsTableLoading(false)
        handleRequestErrors(err, true)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, search, tab])

  useEffect(()=>{
    WalletService.prodIncomeActivity({
      type: query,
      start: startDate,
      end: endDate,
    })
      .then((response) => response.data)
      .then((res) => {
        setFundingGraphData(res.data)
      })
      .catch((err) => {})
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterOptions = [
    { label: "All", value: "all"},
    { label: "Last 7 days", value: "last_seven_days"},
    { label: "This Month", value: "this_month"},
    { label: "Last Month", value: "last_month"},
    { label: "Last 3 Months", value: "last_three_months"},
    { label: "Last 6 Months", value: "last_six_months"},
    { label: "Last 12 Months", value: "last_twelve_months"},
    { label: "Custom", value: "custom"},
  ]

  const csvConfig = mkConfig({ 
    useKeysAsHeaders: false,
    columnHeaders: [
        { displayLabel: "ID", key: "businessId" },
        { displayLabel: "Business Name", key: "businessName" },
        { displayLabel: "Type", key: "type" },
        { displayLabel: "Action", key: tab==="credit" ? "paymentSource":"debitAction" },
        { displayLabel: "Date", key: "created_at" },
        { displayLabel: "Amount", key: tab==="credit" ? "amountPaid":"debitAmount" },
    ],
    filename: "wallet transactions"
  });

  const handleDownload = () => {
    WalletService.getWalletTransactionsCSV({
      search,
      type: query, 
      path: tab,
      start: startDate,
      end: endDate,
    })
      .then((response) => response.data)
      .then((res) => {
        if(res.data.length>0){
            const csv = generateCsv(csvConfig)(res.data);
            download(csvConfig)(csv)
        }
      })
      .catch((err) => {})
  }

  return (
      <div className='mt-10'>
        <div className='flex justify-end mb-10'>
          <Filter
            placeHolder="This month"
            options={
              filterOptions.map((item) => ({
                label: item.label,
                value: item.value
              }))
            }
            containerClass="border-ee-primary text-base text-[#8A8A8A] flex-row-reverse"
            icon={selectArrow}
            iconClassName="!w-[10px]"
            optionItemClass="text-sm text-xs font-semibold p-2 rounded-md m-0 hover:bg-ee-grey-3"
          />
        </div>
        <div className='grid grid-cols-4 gap-y-8 gap-x-[20px] box-border mb-[60px]'>
          {
            [
              {
                title: "Total number of Wallets",
                value: formatBigNumber(walletStats?.totalWallets || walletFunded?.totalWallets || 0, true),
                icon: walletLightGreen,
              },
              {
                title: "Wallet Funded",
                value: formatBigNumber(walletStats?.walletsFunded||walletFunded?.walletsFunded||0, true),
                icon: walletLightGreen,
              },
              {
                title: "Total Amount Funded",
                value: currencyFormatter(walletStats?.totalWalletFunding||0),
                icon: walletDarkBlue,
              },
              // {
              //   title: "Wallet Funded via Paystack",
              //   value: currencyFormatter(walletStats?.walletsViaPaystack||walletFunded?.walletsViaPaystack||0),
              //   icon: walletLightBlue,
              // },
              // {
              //   title: "Wallet Funded via Monnify",
              //   value: currencyFormatter(walletStats?.walletsViaMonnify||walletFunded?.walletsViaMonnify||0),
              //   icon: walletDarkGreen,
              // },
              // {
              //   title: "Wallet Funded via Providus",
              //   value: currencyFormatter(walletStats?.walletsViaProvidus||0),
              //   icon: walletDarkGreen,
              // },
            ].map(item => (
              <div key={item.title}>
                <DataBox
                  title={item.title}
                  icon={<img src={item.icon} alt={item.title} className='w-[20px]' />}
                  value={item.value}
                  className="!shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]"
                />
              </div>
            ))
          }
        </div>
        <div>
          <CustomBarChart
            hideMonthButton={true}
            data={fundingGraphData}
            title="Income"
            yAxisName="Amount Funded"
            subtitle="Total Amount Funded"
            removeFirst={true}
            currencyFormat={true}
            months={month ?? []}
          />
        </div>
        <div className='border-[1px] border-[#EAECF0] rounded-lg mt-[60px] mb-10'>
          <div className='flex items-center justify-between mt-3 mx-[10px]'>
            <h4 className='text-lg font-medium'>Transaction Activities</h4>
            <SearchField
              placeholder="Search..."
            />
          </div>
          <Tabs 
            tabs={[
              {
                label: "All Transactions",
                key: "all"
              },
              {
                label: "Credit Transactions",
                key: "credit"
              },
              {
                label: "Debit Transactions",
                key: "debit"
              },
            ]}
            variant="filled"
            containerClass="!border-0"
          />
          <Table
            loading={isTableLoading}
            data={tableData?.results||[]}
            backendPaginated={true}
            totalPages={tableData?.totalPages ?? 0}
            theadClass="!font-bold py-2.5 text-[#667085] bg-[#F9FAFB] text-sm"
            tdataClass="py-6 pl-[10px] text-sm"
            hideTableTop={true}
            hideOverallTableBorder={true}
            hideTableBodyBorder={true}
            hideLines={true}
            showDownload={true}
            onDownloadClick={()=>{
              handleDownload()
            }}
            rows={[
                {
                    header:'ID',
                    view: (item) => (
                        <div className='!w-[150px] text-ellipsis overflow-hidden'>
                            <span>
                                #{item?.businessId||"---"}
                            </span>
                        </div>
                    ),
                },
                {
                    header:'Business Name',
                    view: (item) => (
                        <div className='max-w-[300px]'>
                            <span>{item?.businessName||"---"}</span>
                        </div>
                    ),
                },
                {
                    header:'Type',
                    view: (item) => (
                      <Status 
                          text={item?.type}
                          type={
                              item?.type === 'Debit' ? "unverified" : 'verified'
                          }
                          addClass="!text-sm py-1.5 px-4"
                          hideDot={true}
                      />
                    ),
                },
                {
                    header:'Action',
                    view: (item) => (
                        <span>
                            {item?.debitAction||item?.paymentSource||"---"}
                        </span>
                    ),
                },
                {
                    header:'Date',
                    view: (item) => (
                        <span>
                            {dayjs(item?.created_at?.split("Z")?.[0]).format("DD/MM/YYYY hh:mmA")}
                        </span>
                    ),
                },
                {
                    header:'Amount',
                    view: (item) => (
                        <span 
                          className={clsx(
                            item.type==="Credit" ? 'text-[#03A89E]':'text-[#FF69B4]'
                          )}
                        >
                            {
                              currencyFormatter(
                                item.type==="Credit" ? (item?.amountPaid||0)
                                : (item?.debitAmount||item?.amountPaid||0)
                              )
                            }
                        </span>
                    ),
                },
            ]}
          />
        </div>
     </div>
  )
}

export default Wallets